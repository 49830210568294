<template>
    <footer>
		<ul>
			<li>© 2022 Make a cake</li>
			<li>Aviso de privacidad</li>
			<li>Terminos y condiciones</li>
			<p class="text-center">Vasco de Quiroga 3200, Santa Fe, Zedec Sta Fé, Álvaro Obregón, 01219 Ciudad de México, CDMX<br>Horario de atención: 10 a.m - 9:00 p.m<br>Tel. 3333333333</p>
		</ul>
	</footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
	footer { background: #f68a8f; color: white; padding: 10px 40px; margin-top: 100px }
	footer ul { margin: 0px; padding: 0px; text-align: center; list-style: none; }
	footer ul li { display: inline-block; padding: 0px 20px; }
	footer p { margin-bottom: 0px; margin-left: auto; margin-right: auto; padding-top: 10px; }
</style>
